@import '../../theme/index.module.scss';

.FormContainer {
  width: 100%;
}

.PlaceHolder {
  min-height: vr(1);
}

.FormWrap {
  margin-bottom: 0;
  color: #fff;
  text-align: center;
  height: 100%;
  display: flex;
  justify-content: center;
  width: 100%;
}

.FormContent {
  background: white;
  padding: 2em 1em;
  width: 100%;
  box-shadow: $box-shadow;

  @include bp($larger) {
    padding: 1.8rem 2rem;
    width: 1050px;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
  }

  h1 {
    color: black;
    font-size: 23px;
    text-transform: uppercase;
    line-height: 27px;
    font-weight: normal;
    letter-spacing: .1em;
    text-shadow: $light-shadow;

    @include bp($larger) {
      width: 25%;
      text-align: left;
    }
  }

  p {
    color: black;
    font-size: 15px;
    font-weight: 400;
    margin: 2em 0 0;
  }

  form {
    @include bp($large) {
      width: 75%;
      display: flex;
      align-items: baseline;
      margin: auto;
      justify-content: space-around;
    }
  }
}

.FormFrame {
  padding: 0 1.45rem 1.45rem 1.45rem;
  border: 2px solid $white;
  border-radius: 5px;
  font-size: 1.125rem;

  @include bp($large) {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
}

.error {
  font-size: ms(-1);
  text-indent: vr(0.5);
  text-align: left;
}

.Well {
  composes: well_l from "../../theme/layout.module.scss";
  padding: 0;

  @include bp($large) {
    padding-bottom: 0;
  }

  button {
    color: $red;
    text-transform: uppercase;
    letter-spacing: 2px;
    border: none;
    background: transparent;
    font-size: 18px;
    font-weight: 700;

    &:hover {
      border: none;
      color: $red;
      background: transparent;
    }

    span {
      display: flex;
      align-items: center;
      cursor: pointer;

      svg {
        margin-left: .5em;

        path {
          fill: $red;
        }
      }
    }
  }
}

.Field {
  text-align: left;

  @include bp($large) {
    width: 48%;
  }
}

.IndentField {
  composes: Field;
  margin-left: 0;
  @include bp($small) {
    margin-left: 40px;
  }
}